var site = site || {};
var generic = generic || {};

site.address_th_autocomplete = function(args) {
    var subdistrictField = args.subdistrictField;
    var districtField = args.districtField;
    var provinceField = args.provinceField;
    var postcodeField = args.postcodeField;
    var isBangkokField = args.isBangkokField || null;
    var subdistrictResults = args.subdistrictResults;
    var districtResults = args.districtResults;
    var provinceResults = args.provinceResults;
    var postcodeResults = args.postcodeResults;
    var updateShipMethods = args.updateShipMethods || false;
    var updatePaymentMethods = args.updatePaymentMethods || false;


    overlayContainerClicked = false;
    // this is a hack for IE and chrome so that when we use the scrollbar on the overlay,
    // and a field in the form loses focus, we can detect that and keep it from hiding address suggestion divs
    var ovcontainer = $('.signin-overlay-wrapper')[0];
    $(ovcontainer).bind("scroll", function() {
        overlayContainerClicked = true;
    });

    return {
        init:  function() {

            if ( !subdistrictField || !districtField || !provinceField || !postcodeField || !subdistrictResults || !districtResults || !provinceResults || !postcodeResults ) {
                return 0;
            }

            // as we enter characters, do a lookup against the db
            $(subdistrictField).bind("keyup", function(evt) {
                lookup(subdistrictField, subdistrictResults, "subdistrict", evt);
            });
            $(districtField).bind("keyup", function(evt) {
                lookup(districtField, districtResults, "district", evt);
            });
            $(provinceField).bind("keyup", function(evt) {
                lookup(provinceField, provinceResults, "province", evt);
            });
            $(postcodeField).bind("keyup", function(evt) {
                lookup(postcodeField, postcodeResults, "postcode", evt);
            });

            // we want blur events, because otherwise if you move on to a different field while a suggestion drop-down is still present, it won't go away
            // however, it turns out we need a timeout, because if you click on one of the suggested values, the original field loses focus,
            // and for some reason the blur event fires but not the click event, and the new value does not get selected.
            $(subdistrictField).bind("blur", function(evt) {
                setTimeout( function() {
                    if (updateShipMethods) {
                        site.checkout.updateShipMethodsByFields(subdistrictField.value, districtField.value, provinceField.value, postcodeField.value);
                    }
                    if (updatePaymentMethods) {
                        site.checkout.toggleCOD();
                    }
                    // in combination with some logic in address_form.js, this helps IE and Chrome so if you click on the address overlay's scroll bar, it
                    // won't cause the search suggestions to go away.
                    if ( !overlayContainerClicked ) {
                        killAllResults();
                    } else {
                        overlayContainerClicked = false;
                        $(subdistrictField).focus();
                    }
                }, 200 );
            });
            $(districtField).bind("blur", function(evt) {
                setTimeout( function() {
                    if (updateShipMethods) {
                        site.checkout.updateShipMethodsByFields(subdistrictField.value, districtField.value, provinceField.value, postcodeField.value);
                    }
                    if (updatePaymentMethods) {
                        site.checkout.toggleCOD();
                    }
                    // in combination with some logic in address_form.js, this helps IE and Chrome so if you click on the address overlay's scroll bar, it
                    // won't cause the search suggestions to go away.
                    if ( !overlayContainerClicked ) {
                        killAllResults();
                    } else {
                        overlayContainerClicked = false;
                        $(districtField).focus();
                    }
                }, 200 );
            });
            $(provinceField).bind("blur", function(evt) {
                setTimeout( function() {
                    if (updateShipMethods) {
                        site.checkout.updateShipMethodsByFields(subdistrictField.value, districtField.value, provinceField.value, postcodeField.value);
                    }
                    if (updatePaymentMethods) {
                        site.checkout.toggleCOD();
                    }
                    // in combination with some logic in address_form.js, this helps IE and Chrome so if you click on the address overlay's scroll bar, it
                    // won't cause the search suggestions to go away.
                    if ( !overlayContainerClicked ) {
                        killAllResults();
                    } else {
                        overlayContainerClicked = false;
                        $(provinceField).focus();
                    }
                }, 200 );
            });
            $(postcodeField).bind("blur", function(evt) {
                setTimeout( function() {
                    if (updateShipMethods) {
                        site.checkout.updateShipMethodsByFields(subdistrictField.value, districtField.value, provinceField.value, postcodeField.value);
                    }
                    if (updatePaymentMethods) {
                        site.checkout.toggleCOD();
                    }
                    // in combination with some logic in address_form.js, this helps IE and Chrome so if you click on the address overlay's scroll bar, it
                    // won't cause the search suggestions to go away.
                    if ( !overlayContainerClicked ) {
                        killAllResults();
                    } else {
                        overlayContainerClicked = false;
                        $(postcodeField).focus();
                    }
                }, 200 );
            });

            if($(postcodeField).val().length >0){
                setTimeout( function() {
                    if (updateShipMethods) {
                        site.checkout.updateShipMethodsByFields(subdistrictField.value, districtField.value, provinceField.value, postcodeField.value);
                    }
                    if (updatePaymentMethods) {
                        site.checkout.toggleCOD();
                    }
                    // in combination with some logic in address_form.js, this helps IE and Chrome so if you click on the address overlay's scroll bar, it
                    // won't cause the search suggestions to go away.
                    if ( !overlayContainerClicked ) {
                        killAllResults();
                    } else {
                        overlayContainerClicked = false;
                        $(postcodeField).focus();
                    }
                }, 200 );
            }

            // get rid of all the suggestion drop-downs.
            var killAllResults = function() {
                $(subdistrictResults).html('');
                $(districtResults).html('');
                $(provinceResults).html('');
                $(postcodeResults).html('');
                $(subdistrictResults).addClass('hidden');
                $(districtResults).addClass('hidden');
                $(provinceResults).addClass('hidden');
                $(postcodeResults).addClass('hidden');
            };

            // once you've selected a value off a suggestion list, put these values into the address fields
            var autofill = function(val) {
                var data = val.split(",");
                subdistrictField.value = data[0];
                districtField.value = data[1];
                provinceField.value = data[2];
                postcodeField.value = data[3];
                if(isBangkokField) {
                    isBangkokField.value = data[4];
                }
                subdistrictField.style.background = '#ffffff';
                districtField.style.background = '#ffffff';
                provinceField.style.background = '#ffffff';
                postcodeField.style.background = '#ffffff';

                killAllResults();
            };

            // look up addresses that start with an entered value in a particular address field
            var lookup = function(obj, res, type, evt) {
                console.log('in lookup', obj, type);
                var lookupField = obj;

                // in case any existing drop-downs are present, kill them
                killAllResults();

                // this is for checking later if we have an exact match
                var idx = 0;
                if ( type === "district" ) {
                    idx = 1;
                }
                if ( type === "province" ) {
                    idx = 2;
                }
                if ( type === "postcode" ) {
                    idx = 3;
                }

                if (lookupField.value == '') {
                    // nothing to look up yet
                    $(res).html('');
                    $(res).addClass('hidden');
                } else {
                    // this is to figure out the offsets so the drop-down sits in the right place
                    var thisobj = lookupField;
                    if (thisobj.offsetParent) {
                        x = thisobj.offsetLeft;
                        y = thisobj.offsetTop;
                        h = thisobj.offsetHeight;
                        w = thisobj.offsetWidth;
                        while (thisobj = thisobj.offsetParent) {
                            console.log('in lookup -offsetParent', thisobj.offsetParent, $(thisobj));
                            if ($(thisobj).hasClass("overlay-container") || $(thisobj).hasClass("body-container") || $(thisobj).hasClass("checkout-wrapper") ) {
                                // for some reason the overlay container messes things up
                                continue;
                            }
                            x += thisobj.offsetLeft;
                            y += thisobj.offsetTop;
                        }
                    }
                }

                var charLength = lookupField.value.length;
                var innerHTML = '';
                var exactItem = false;

                if ( charLength > 0 ) {
                    // we have something to look up!
                    var paramStr = "";
                    paramStr += '"type":"' + type + '",';
                    paramStr += '"value":"' + encodeURIComponent(lookupField.value) + '",';
                    var paramObj = eval("(" + "{"+paramStr.substring(0,(paramStr.length-1)).replace("undefined","").replace("%40","@")+"}" + ")");

                    generic.jsonrpc.fetch({
                        method: 'address.GetAddressDataByFieldStartsWith',
                        //params: [{type: type, value: lookupField.value}],
                        params: [paramObj],
                        onSuccess: function(jsonRpcResponse) {
                            var responseData = jsonRpcResponse.getValue();

                            // populate the suggestion rows from the data we got back from the db
                            for (i=0; i<responseData.length; i++) {
                                var addr_data = responseData[i].split(",");
                                innerHTML += '<tr class="address-results-row" onmouseover="this.style.background = \'#DDDDDD\'" onmouseout="this.style.background = \'#EEEEEE\'"><td align="left">' + addr_data[0] + '</td><td align="left">' + addr_data[1] + '</td><td align="left">' + addr_data[2] + '</td><td align="left">' + addr_data[3] + '</td><td style="display:none">' + addr_data[4] + '</tr>';
                                if ( addr_data[idx].toLowerCase() == lookupField.value.toLowerCase() ) {
                                    exactItem = true;
                                }
                                if ( i >= 19 ) {
                                    // only show up to 20 entries at once
                                    innerHTML += '<tr class="address-results-row-other"><td align="left">...</td><td align="left"></td><td align="left"></td><td align="left"></td><td style="display:none"></td></tr>';
                                    break;
                                }
                            }
                            // stick the rows into a table and put it in the appropriate result object
                            $(res).html('<table width="300" border="0" cellpadding="0" cellspacing="0" >'+ innerHTML +"</table>");

                            // if we found nothing, indicate this by making the field pink; otherwise white
                            // NOTE: removing this as we're no longer requiring these values to come from the db, per Yuliya and Faye. eerkkila 2013-01-08
                            //if (responseData.length == 0) {lookupField.style.background = '#ffaaaa';}
                            //else {lookupField.style.background = '#ffffff';}
                            lookupField.style.background = '#ffffff';

                            // NOTE: modifying this as we're no longer requiring these values to come from the db, per Yuliya and Faye. eerkkila 2013-01-08
                            if (exactItem && responseData.length < 2) {
                                // if we only got one match and it was exact, fill the fields with the single result
                                autofill(responseData[0]);
                                lookupField.style.background = '#ffffff';
                                $(res).html('');
                                $(res).addClass('hidden');
                            }
                            else if ( responseData.length == 0) {
                                //// we got no result...indicate error
                                //lookupField.style.background = '#ffaaaa';
                                $(res).html('');
                                $(res).addClass('hidden');
                            }
                            else {
                                // we got one or more results: properly offset the results and display them!
                                lookupField.style.background = '#ffffff';
                                res.style.left = x + 'px';
                                res.style.top = (y + h) + 'px';
                                $(res).removeClass('hidden');
                            }

                            // set up listeners for the rows so if you click one, it fills the values into the address fields
                            var rows = $('tr.address-results-row');
                            if ( rows && rows.length > 0 ) {
                                rows.bind("mousedown", function(evt) {
                                    evt.preventDefault();
                                    var obj = this;
                                    var cols = $(obj).find("td");
                                    // the cols hold the 4 address values; concatenate them to autofill
                                    if ( cols && cols.length > 0 ) {
                                        var addrstr = "";
                                        for (j=0; j<cols.length; j++) {
                                            if ( j != 0 ) {
                                                addrstr += ",";
                                            }
                                            addrstr += $(cols[j]).html();
                                        }
                                        autofill(addrstr);
                                        if (updateShipMethods) {
                                            site.checkout.updateShipMethodsByFields(subdistrictField.value, districtField.value, provinceField.value, postcodeField.value);
                                        }
                                        if (updatePaymentMethods) {
                                            site.checkout.toggleCOD();
                                        }
                                    }
                                 });
                            }
                        },
                        onFailure: function (jsonRpcResponse) {
                            // oops
                            var errorObjectsArray = jsonRpcResponse.getMessages();
                            //var errListNode = addressForm.select("ul.error_messages")[0];
                            //generic.showErrors(errorObjectsArray, errListNode, addressForm);
                        }
                    }); // end generic.jsonrpc.fetch
                } else {
                    // nothing to look up
                }

            };
        }
    }
};

$(document).ready(function() {
  var zdc = {};
  zdc.rb = generic.rb('error_messages');
  var zdc_error_response = zdc.rb.get('zdc_out_of_stock');
  $('.addZdcOfferToBag').on('click', function(e) {
    var zdc_redeemed = 0;
    e.preventDefault();
    var offerCode = $(this).data('offer');
    generic.jsonrpc.fetch({
      method: 'rpc.form',
      params: [ {
        _SUBMIT: 'offer_code',
        OFFER_CODE: offerCode
      }],
      onSuccess: function() {
        var containerDiv = $('<div/>', { 'class': 'overlay-content' });
        $('body').append(containerDiv);
        $.ajax('/templates/zero_dollar_checkout_popup.tmpl', {
          method: 'get',
          success: function(transport) {
            containerDiv.append(transport);
            generic.overlay.launch({
              content: containerDiv,
              includeBackground: true,
              cssStyle: {height: '400px', width: '600px'},
              cssClass: ''
            });
          }
        });
      },
      onFailure: function(jsonRpcResponse) {
        var errorObj = jsonRpcResponse.getError();
        var msgObj = jsonRpcResponse.getData();
        var error_messages = '';
        if (msgObj && msgObj.messages) {
          zdc_error_response = zdc.rb.get('zdc_applied');
          $(msgObj.messages).each(function(index, responseMessages) {
            if (responseMessages.key.indexOf('offer_criteria_not_met') !== -1) {
              error_messages += responseMessages.text + '<br/>';
              zdc_redeemed = 1;
            } else {
              error_messages += zdc_error_response + '<br/>';
              return false;
            }
          });
        } else if (errorObj && errorObj.data && errorObj.data.messages) {
          $(errorObj.data.messages).each(function(index, errorMessages) {
            if (errorMessages.key.indexOf('out_of_stock') !== -1) {
              error_messages += zdc_error_response + '<br/>';
              return false;
            } else {
              error_messages += errorMessages.text + '<br/>';
            }
          });
        }
        var containerDiv = $('<div/>', { 'class': 'overlay-content' });
        $('body').append(containerDiv);
        $.ajax('/templates/zero_dollar_checkout_popup.tmpl', {
          method: 'get',
          success: function(transport) {
            containerDiv.append(transport);
            $('.popup_content_text.zero-dollar-success').addClass('hidden');
            $('.popup_content_text.zero-dollar-error').removeClass('hidden').html(error_messages);
            if (zdc_redeemed) {
              $('.checkout-buttons').addClass('hidden');
            }
            generic.overlay.launch({
              content: containerDiv,
              includeBackground: true,
              cssStyle: {height: '400px', width: '600px'},
              cssClass: ''
            });
          }
        });
      }
    });
  });
});
